import { IDividerFields } from 'contentful-types'

import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'

// Component
import Divider, { DividerProps } from './Divider'

const ContentfulDivider = withContentful<IDividerFields, DividerProps>(Divider)

export default ContentfulDivider
